import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'

const IndexPage = ({ data }) => {
  const entry = data.craftGql.home[0]
  return (
    <Layout isHome={true}>
      <SEO
        title={entry.titleOverride || entry.title}
        description={entry.metaDescription}
        cardSize={`large`}
        image={entry.metaImage[0]}
        isHome={true}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    craftGql {
      home: entries(section: ["homepage"], limit: 1) {
        ... on CraftGQL_homepage_homepage_Entry {
          title
          titleOverride
          metaDescription
          metaImage {
            url
          }
        }
      }
    }
  }
`

export default IndexPage
